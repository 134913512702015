import references from "../../services/references";
import React, { Fragment, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { WBPToggleButton } from "../../StyledComponents";
import { useTranslation } from "react-i18next";
import UserContext from "../../contexts/UserContext";
import WBPAddressText from "./WBPAddressText";

export const DonationSelector = ({
  currency,
  selectedDonation,
  submitDonation,
}) => {
  const donationOptions = references.defaultOrders.donationOptions;
  return (
    <>
      {Object.keys(donationOptions).map((key) => {
        const option = donationOptions[key];
        return (
          <Fragment key={key}>
            <Grid item xs={4}>
              <WBPToggleButton
                size="small"
                key={key}
                variant="outlined"
                color="primary"
                selected={option.value === selectedDonation}
                value={key}
                onClick={(event) => submitDonation(event, option.value, true)}
              >
                {currency + " " + option.value}
              </WBPToggleButton>
            </Grid>
          </Fragment>
        );
      })}
    </>
  );
};

export const SummaryAddress = ({
  shippingAddressID,
  billingHeader = "Billing address",
  alternativeHeader = "Return address",
  showBillingAddress = true,
}) => {
  const { t } = useTranslation();
  const [user] = useContext(UserContext);

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        alignSelf="flex-start"
        item
        xs={12}
        sm={6}
      >
        <Grid item xs>
          <Typography variant="h6">{t(alternativeHeader)}</Typography>
        </Grid>
        <Grid item xs>
          <WBPAddressText
            address={user.addresses.find(
              (address) => address.id === shippingAddressID
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        alignSelf="flex-start"
        item
        xs={12}
        sm={6}
        display={showBillingAddress ? "" : "none"}
      >
        <Grid item xs>
          <Typography variant="h6">{t(billingHeader)}</Typography>
        </Grid>
        <Grid item xs>
          <WBPAddressText
            address={
              user.addresses.length > 1
                ? user.addresses.find((address) =>
                    address.tags.includes("BILLING")
                  )
                : user.addresses[0]
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
