import React, { useContext, useState, useEffect, Fragment } from "react";
import BeeCareOrderContext from "../contexts/BeeCareOrderContext";
import history from "../services/history";
import {
  Typography,
  Box,
  Divider,
  Button,
  Grid,
  OutlinedInput,
  FormControl,
  FormHelperText,
  IconButton,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import API from "../services/api";
import SettingsContext from "../contexts/SettingsContext";
import { useMount } from "react-use";
import {
  ArrowForwardIosOutlined,
  Delete,
  HelpOutline,
} from "@mui/icons-material";
import BeeCareOrderSideBar from "./BeeCareOrderSideBar";
import WBPPage from "./sub-components/WBPPage";
import UserContext from "../contexts/UserContext";
import UIContext from "../contexts/UIContext";
import DonationSummary from "./sub-components/DonationSummary";
import Client from "shopify-buy";
import references from "../services/references";
import DiscountToolTip from "./sub-components/DiscountToolTip";
import {
  DonationSelector,
  SummaryAddress,
} from "./sub-components/SummaryComponents";

const useStyles = makeStyles((theme) => ({
  formRoot: { width: "100%" },
  btnSize: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: "10px",
      width: "100%",
    },
  },
  padless: {
    padding: 7,
  },
  countColumn: {
    textAlign: "center",
  },
  itemColumn: {},
  priceColumn: {
    textAlign: "end",
  },
  deleteColumn: {
    textAlign: "end",
  },
  mobiWidth: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  },
  totalPrice: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: "bold",
  },
  divider: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  deleteIconButton: {
    padding: 0,
  },
}));

const SendInBoxStep3 = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [beeCareOrder, setBeeCareOrder] = useContext(BeeCareOrderContext);
  const [voucherCode, setVoucherCode] = useState("");
  const settings = useContext(SettingsContext).beecare;
  const [user] = useContext(UserContext);
  const [, , loader] = useContext(UIContext);
  const [voucherError, setVoucherError] = useState("");
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState(references.defaultOrders.beecare.price);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  useMount(() => {
    if (beeCareOrder.amount === 0) {
      history.push("/sendinbox/return-houses");
    }
  });

  useEffect(() => {
    setCurrency(settings ? settings.currency : "");
  }, [settings.currency]);

  useEffect(() => {
    if (beeCareOrder.variant && beeCareOrder.variant.price) {
      setPrice(
        typeof beeCareOrder.variant.price === "object" &&
          beeCareOrder.variant.price.amount
          ? Number(beeCareOrder.variant.price.amount)
          : Number(beeCareOrder.variant.price)
      );
    }
  }, [beeCareOrder.variant]);

  const shopifyClient = Client.buildClient({
    domain: new URL(
      JSON.parse(process.env.REACT_APP_SHOPIFY_URL)[i18n.language]
    ).host,
    storefrontAccessToken: JSON.parse(
      process.env.REACT_APP_SHOPIFY_ACCESSTOKEN
    )[i18n.language],
    language: i18n.language,
  });
  const sendData = (shouldSkip) => {
    const data = {
      address_id: beeCareOrder.address_id,
      amount: beeCareOrder.amount,
      houses: beeCareOrder.houses,
      vouchers: beeCareOrder.discount_vouchers.map((voucher) => voucher.id),
      total_price: beeCareOrder.total_price,
      // donate: beeCareOrder.donation,
      type: beeCareOrder.type,
    };

    if (shouldSkip && Number(beeCareOrder.total_price) > 0) {
      data.psp = beeCareOrder.psp;
    }

    loader.addTask("ORDER");
    loader.addTask("CHECKOUT");

    // Use Cart API instead of deprecated Checkout API
    createShopifyCart();
  };
  const createShopifyCart = async () => {
    try {
      // Create a new cart
      const cart = await shopifyClient.cart.create();

      // Add cart attributes
      const cartWithAttributes = await shopifyClient.cart.updateAttributes(
        cart.id,
        {
          attributes: {
            seasonType: "beecare",
          },
        }
      );

      // Get the selected address
      const selectedAddress = user.addresses.find(
        (address) => address.id === beeCareOrder.address_id
      );

      // Build the shipping address
      const shippingAddress = {
        address1: selectedAddress.address_1,
        address2: selectedAddress.address_2,
        city: selectedAddress.town,
        company: selectedAddress.company_name,
        country: selectedAddress.country_code,
        firstName: selectedAddress.first_name,
        lastName: selectedAddress.last_name,
        phone: "",
        province: "",
        zip: selectedAddress.postal_code,
      };

      // Update buyer identity (including shipping address)
      const cartWithBuyerIdentity =
        await shopifyClient.cart.updateBuyerIdentity(cartWithAttributes.id, {
          email: user.email,
          deliveryAddressPreferences: [
            {
              deliveryAddress: shippingAddress,
            },
          ],
        });

      // Build line items
      const lineItems = [];

      // Get houses string
      const shopifyHouses = beeCareOrder.selectedHouses
        .map((house) => house)
        .join(", ");

      // Add main product items (if any)
      const quantity =
        beeCareOrder.amount - beeCareOrder.discount_vouchers.length;
      if (quantity > 0) {
        lineItems.push({
          merchandiseId: beeCareOrder.variant.id,
          quantity: quantity,
          attributes: [
            {
              key: "houses",
              value: shopifyHouses,
            },
          ],
        });
      }

      // Add voucher items (if any)
      if (beeCareOrder.discount_vouchers.length > 0) {
        const freeVariant = settings.shopify.products.variants.find(
          (variant) => {
            let freePrice = variant.price;
            if (typeof freePrice === "object" && freePrice.amount) {
              freePrice = freePrice.amount;
            }
            return Number(freePrice) === 0;
          }
        );

        lineItems.push({
          merchandiseId: freeVariant.id,
          quantity: beeCareOrder.discount_vouchers.length,
          attributes: [
            {
              key: "vouchers",
              value: beeCareOrder.discount_vouchers
                .map((voucher) => voucher.code)
                .join(", "),
            },
            {
              key: "houses",
              value: shopifyHouses,
            },
          ],
        });
      }

      // Add donation if present
      if (beeCareOrder.donation > 0) {
        const donationVariant = settings.shopify.donationOptions.variants.find(
          (variant) => variant.sku === "donation-" + beeCareOrder.donation
        );

        lineItems.push({
          merchandiseId: donationVariant.id,
          quantity: 1,
        });
      }

      // Filter out any items with zero quantity and add them to the cart
      const cartWithLines = await shopifyClient.cart.addLines(
        cartWithBuyerIdentity.id,
        lineItems.filter((item) => item.quantity > 0)
      );

      // Get the checkout URL
      let checkoutURL = await shopifyClient.cart.checkoutUrl(cartWithLines.id);

      // Add locale parameter if needed
      if (i18n.language === "fr-CH") {
        checkoutURL += "&locale=fr";
      }

      // Redirect to checkout
      gotoShopifyCheckout(checkoutURL);
    } catch (error) {
      shopifyError(error);
    }
  };
  const gotoShopifyCheckout = (checkoutURL) => {
    API.get(`me/shopify-login?return_to=` + checkoutURL)
      .then((response) => {
        loader.clearTask("CHECKOUT");
        window.open(response.data.url, "_self");
      })
      .catch(shopifyError);
  };

  const shopifyError = (error) => {
    loader.clearTask("CHECKOUT");
    console.warn(error);
  };

  const calculateTotals = (donation, discount_vouchers) => {
    const totalPrice =
      settings && beeCareOrder.amount
        ? beeCareOrder.amount * price -
          discount_vouchers.length * price +
          donation
        : 0;
    setBeeCareOrder((prevBeeCareOrder) => ({
      ...prevBeeCareOrder,
      total_price: totalPrice,
    }));
  };

  const deleteAllDiscounts = (event) => {
    event.preventDefault();
    setBeeCareOrder((oldOrder) => ({
      ...oldOrder,
      discount_vouchers: [],
    }));
    calculateTotals(beeCareOrder.donation, []);
  };

  // toggleDonations is what we use to differentiate toggleable donation buttons from the custom donation
  const submitDonation = (event, donationValue = 0, toggleDonation = false) => {
    event.preventDefault();
    const newDonation =
      toggleDonation && beeCareOrder.donation === donationValue
        ? 0
        : donationValue;
    setBeeCareOrder((oldOrder) => ({
      ...oldOrder,
      donation: newDonation,
    }));
    calculateTotals(newDonation, beeCareOrder.discount_vouchers);
  };

  const addVoucherHandler = (event) => {
    event.preventDefault();
    setVoucherError("");
    setVoucherCode(event.target.value.toUpperCase().trim());
  };
  const submitVoucherCode = (event) => {
    event.preventDefault();
    setVoucherError("");
    if (voucherCode.toUpperCase().indexOf("FREECARE") < 0) {
      if (
        !beeCareOrder.discount_vouchers.some(
          (voucher) => voucher.code === voucherCode
        )
      ) {
        loader.addTask("VOUCHER");
        API.post(`me/vouchers`, { code: voucherCode, type: "BEECARE" })
          .then((res) => {
            loader.clearTask("VOUCHER");
            const discount_vouchers = [...beeCareOrder.discount_vouchers];
            discount_vouchers.push(res.data);
            setVoucherCode("");
            setBeeCareOrder((oldOrder) => ({
              ...oldOrder,
              discount_vouchers: discount_vouchers,
            }));
            calculateTotals(beeCareOrder.donation, discount_vouchers);
          })
          .catch((error) => {
            loader.clearTask("VOUCHER");
            console.warn(error);
            setVoucherError(t(error.response.data.detail));
          });
      } else {
        loader.clearTask("VOUCHER");
        setVoucherError(t("Voucher code has already been entered"));
      }
    } else {
      loader.clearTask("VOUCHER");
      setVoucherError(t("Invalid code"));
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    sendData(Number(beeCareOrder.total_price) === 0 ? true : false);
  };

  return (
    <div>
      <BeeCareOrderSideBar step={2} showSummary>
        <WBPPage title={t("323")} description={t("324")}>
          <Box mt={-1} mb={2}>
            <Grid container alignItems="center">
              <SummaryAddress shippingAddressID={beeCareOrder.address_id} />
              <Grid container direction="row" pt={1}>
                <Typography variant="h6">{t("Order summary")}</Typography>
              </Grid>
              <Grid container direction="row" pt={1} alignItems="flex-start">
                <Grid item xs={1} className={classes.countColumn}>
                  <Typography variant="body1">{beeCareOrder.amount}</Typography>
                </Grid>
                <Grid item xs={7} className={classes.itemColumn}>
                  <Typography variant="body1">{t("BeeCare")}</Typography>
                  {beeCareOrder.priceReduction > 0 && (
                    <DiscountToolTip
                      priceReduction={beeCareOrder.priceReduction}
                      currency={currency}
                    />
                  )}
                  <Typography variant="body2" color="textSecondary">
                    {t("House Codes")}
                  </Typography>
                  {beeCareOrder.selectedHouses.map((house, key) => {
                    return (
                      <Typography
                        key={key}
                        variant="body2"
                        color="textSecondary"
                      >
                        {house}
                      </Typography>
                    );
                  })}
                </Grid>
                <Grid item xs={3} className={classes.priceColumn}>
                  <Typography variant="body1">
                    {currency +
                      " " +
                      parseFloat(beeCareOrder.amount * price).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.deleteColumn}>
                  &nbsp;
                </Grid>
              </Grid>
              {beeCareOrder.discount_vouchers.length > 0 && (
                <Grid
                  container
                  item
                  direction="row"
                  py={1}
                  alignItems="flex-start"
                >
                  <Grid item xs={1} className={classes.countColumn}>
                    <Typography variant="body1">
                      {beeCareOrder.discount_vouchers.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} className={classes.itemColumn}>
                    <Typography variant="body1">{t("326")}</Typography>
                    {/*<Typography variant="body1">{t("491")}</Typography>*/}
                    <Typography variant="body2" color="textSecondary">
                      {t("329")}
                    </Typography>
                    {beeCareOrder.discount_vouchers.map((voucher, key) => {
                      return (
                        <Typography
                          key={key}
                          variant="body2"
                          color="textSecondary"
                        >
                          {voucher.code}
                        </Typography>
                      );
                    })}
                  </Grid>
                  <Grid item xs={3} className={classes.priceColumn}>
                    <Typography variant="body1">
                      {"- " +
                        currency +
                        " " +
                        parseFloat(
                          beeCareOrder.discount_vouchers.length * price
                        ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.deleteColumn}>
                    <IconButton
                      className={classes.deleteIconButton}
                      onClick={deleteAllDiscounts}
                      size="small"
                    >
                      <Delete></Delete>
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {beeCareOrder.donation > 0 && (
                <Grid container direction="row" py={1} alignItems="center">
                  <Grid item xs={1} className={classes.countColumn}>
                    <Typography variant="body1">1</Typography>
                  </Grid>
                  <Grid item xs={7} className={classes.itemColumn}>
                    <Typography variant="body1">{t("Donation")}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.priceColumn}>
                    <Typography variant="body1">
                      {currency +
                        " " +
                        Number(beeCareOrder.donation).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.deleteColumn}>
                    <IconButton
                      className={classes.deleteIconButton}
                      onClick={submitDonation}
                      size="small"
                    >
                      <Delete></Delete>
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              <Divider className={classes.divider} />
              <Grid container direction="row" py={1}>
                <Grid item xs={1} className={classes.countColumn}>
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={classes.itemTextColumn}
                  alignSelf="center"
                >
                  <Typography variant="body1">{t("Total incl VAT")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={classes.priceColumn}
                  alignSelf="center"
                >
                  <Typography className={classes.totalPrice}>
                    {currency +
                      " " +
                      Number(beeCareOrder.total_price).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <form
                onSubmit={submitVoucherCode}
                className={classes.formRoot}
                hidden={
                  beeCareOrder.selectedHouses.length <=
                  beeCareOrder.discount_vouchers.length
                }
              >
                <Grid
                  container
                  direction="row"
                  xs={12}
                  pt={1}
                  spacing={{ xs: 1 }}
                  alignItems="flex-start"
                  style={{ margin: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    className={classes.itemColumn}
                    alignSelf={"center"}
                  >
                    <Typography variant="body1">{t("328")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.priceColumn}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="ticket_code"
                        variant="outlined"
                        name="ticket_code"
                        onChange={addVoucherHandler}
                        placeholder={t("329")}
                        value={voucherCode}
                        fullWidth
                        error={voucherError ? true : false}
                        classes={{ input: classes.padless }}
                      />
                      <FormHelperText
                        error={
                          voucherError && voucherError !== "" ? true : false
                        }
                      >
                        {voucherError && voucherError !== ""
                          ? voucherError
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} className={classes.deleteColumn}>
                    <Button
                      id="button-sendinboxstep3-addvoucher"
                      variant="contained"
                      color="secondary"
                      type="submit"
                      classes={{ input: classes.padless }}
                      disableElevation
                      fullWidth
                    >
                      {t("106")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Box>
          <form>
            <Box py={2}>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={12} sm={3} md={5}>
                  <Typography variant="body1">
                    {t("Support us")}
                    {isMobile && (
                      <>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="primary"
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                          }}
                          size="large"
                        >
                          <HelpOutline />
                        </IconButton>
                        <Popover
                          id={popoverId}
                          open={openPopover}
                          anchorEl={anchorEl}
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                          onClick={() => {
                            setAnchorEl(null);
                          }}
                          anchorReference="anchorPosition"
                          anchorPosition={{ top: 25, left: 400 }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <DonationSummary showClose />
                        </Popover>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction={"column"}
                  xs={12}
                  sm={9}
                  md={7}
                  justifyContent="space-between"
                >
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    spacing={2}
                  >
                    <DonationSelector
                      currency={currency}
                      selectedDonation={beeCareOrder.donation}
                      submitDonation={submitDonation}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
          <Box my={4}>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={5}>
                <Button
                  id="button-sendinboxstep3-back"
                  className={`${classes.btnSize} ${classes.mobiWidth}`}
                  color="primary"
                  onClick={history.goBack}
                  variant="outlined"
                >
                  {t("404")}
                </Button>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Button
                  id="button-sendinboxstep3-submitwithpayment"
                  className={`${classes.btnSize} ${classes.mobiWidth}`}
                  color="primary"
                  onClick={submitHandler}
                  variant="contained"
                  disableElevation
                >
                  {t("349")}
                  <ArrowForwardIosOutlined fontSize="small" />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </WBPPage>
      </BeeCareOrderSideBar>
    </div>
  );
};

export default SendInBoxStep3;
