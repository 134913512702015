import { Typography, Grid, IconButton, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  countColumn: {
    textAlign: "center",
  },
  itemColumn: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  deliveryColumn: {
    textAlign: "center",
  },
  priceColumn: {
    textAlign: "end",
  },
  deleteColumn: {
    textAlign: "end",
  },
  deleteIconButton: {
    padding: 0,
  },
  productImage: {
    width: 40,
    height: 40,
    borderRadius: 4,
  },
}));

const ProductLine = ({ product, currency, onDelete }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item direction="row" py={1} alignItems="center">
      {/* Quantity Column */}
      <Grid item xs={1} className={classes.countColumn}>
        <Typography variant="body1">{product.quantity || 1}</Typography>
      </Grid>

      {/* Product Info Column */}
      <Grid item xs={6} className={classes.itemColumn}>
        {/* Product Image */}
        {product?.image && (
          <Avatar
            src={product.image}
            alt={product.title}
            variant="square"
            className={classes.productImage}
          />
        )}
        <div>
          {/* Product Title */}
          <Typography variant="body1">
            {product.title.toLowerCase() !== "default title"
              ? product.title
              : product.productTitle}
          </Typography>
          {/* Product Variant (if available) */}

          {product?.productTitle && (
            <Typography variant="body2" color="textSecondary">
              {/* {JSON.stringify(product)} */}
              {product.productTitle.toLowerCase() !== "default title" &&
              product.title.toLowerCase() !== "default title"
                ? product.productTitle
                : ""}
            </Typography>
          )}
        </div>
      </Grid>

      {/* Delivery Time Column */}
      <Grid item xs={2} className={classes.deliveryColumn}>
        <Typography variant="body2" color="textSecondary" textAlign="right">
          {t("3-4 days")}
        </Typography>
      </Grid>

      {/* Price Column */}
      <Grid item xs={2} className={classes.priceColumn}>
        <Typography variant="body1">
          {`${currency} ${parseFloat(
            product?.price * product?.quantity || 0
          ).toFixed(2)}`}
        </Typography>
      </Grid>

      {/* Delete Button Column */}
      <Grid item xs={1} className={classes.deleteColumn}>
        <IconButton
          className={classes.deleteIconButton}
          onClick={() => onDelete(product.id)}
          size="small"
        >
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ProductLine;
