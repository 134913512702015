import { Trans, useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import themeOptions from "../../services/theme-default";
import { ExpandMore } from "@mui/icons-material";
import React from "react";

export default function HeaderTextDropdowns(props) {
  const { t } = useTranslation();
  let transComponent = {
    p: (
      <Accordion
        sx={{ backgroundColor: "transparent", boxShadow: "none" }}
        disableGutters={true}
      />
    ),
    header: (
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      />
    ),
    headerText: (
      <Typography
        variant="h6"
        color={
          props.color !== undefined
            ? props.color
            : themeOptions.palette.primary.contrastText
        }
      />
    ),
    div: <AccordionDetails sx={{ paddingLeft: 3, paddingTop: 0 }} />,
    lnk: (
      <a
        href={t("479-link")}
        style={{ color: "#FFF" }}
        target="_blank"
        rel="noreferrer"
      />
    ),
    vid: (
      <a
        href={t("479-vid")}
        style={{ color: "#FFF" }}
        target="_blank"
        rel="noreferrer"
      />
    ),
  };
  if (props.components) {
    transComponent = { ...transComponent, ...props.components };
  }
  return <Trans i18nKey={props.translationId} components={transComponent} />;
}
